require('./bootstrap');
require('alpinejs');
require('print-js');

// import print from 'print-js'

//
// import Alpine from 'alpinejs'
//
// window.Alpine = Alpine;
//
// Alpine.start();
